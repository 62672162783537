<template>
  <div>
    <v-container v-if="user">
      <v-row no-gutters class="mb-5">
        <v-col cols="12" class="d-flex flex-column justify-start">
          <h4 class="font-weight-bold default--text">
            {{ $t("dashboard.title", [user.firstName]) }}
          </h4>
          <span class="text-caption" v-html="$t('dashboard.subtitle')"> </span>
        </v-col>
      </v-row>
      <!-- <PrimaCardRow /> -->
      <div class="prima-card-row">
        <v-row>
          <v-col cols="12" md="4">
            <span class="text-h6" v-if="fidelityCard">{{
              $t("primaCardRow.header.cardLogged")
            }}</span>
            <span class="text-h6" v-else>{{
              $t("primaCardRow.header.cardSlogged")
            }}</span>

            <transition name="flip" v-if="fidelityCard">
              <v-card
                @click="toggleCard()"
                v-bind:key="flipped"
                class="green lighten-2 d-flex flex-column rounded card-big"
              >
                <img
                  class="card-img align-self-end mr-5 mt-5"
                  src="/img_layout/prima_cards/prima-cards.svg"
                  alt="Prima Card"
                  title="Logo Prima Card"
                />
                <v-card-text
                  v-if="!flipped"
                  class="primary--text text--darken-1"
                >
                  {{ $t("primaCardRow.header.cardLogged") }} <br />
                  <span class="d-block card-info text-h3"
                    >{{ cart.user.firstName }} {{ cart.user.lastName }}</span
                  >
                  {{ $t("primaCardRow.text.cardNumber") }}<br />
                  <span class="d-block card-info text-h3">
                    {{ fidelityCard }}
                  </span>
                  <span style="font-size: 11px"
                    >Fai tap sulla carta per visualizzare il barcode</span
                  >
                </v-card-text>
                <barcode
                  v-else
                  :value="fidelityCard"
                  :options="{ format: 'CODE128', width: 380, height: 150 }"
                  class="align-self-center"
                />
                <!-- <v-icon
                  v-if="flipped"
                  style="position: absolute; bottom: 0px; right: 0"
                  @click.stop="openFidelityModal()"
                  >$fullscreen</v-icon
                > -->
              </v-card>
            </transition>
            <v-card
              v-else
              class="green lighten-2 d-flex flex-column rounded card-big"
            >
              <v-card-text
                v-if="!flipped"
                class="primary--text text--darken-1 mt-5"
              >
                {{ $t("primaCardRow.text.username") }} <br />
                <span class="d-block card-info text-h3"
                  >{{ cart.user.firstName }}<br />
                  {{ cart.user.lastName }}</span
                >
              </v-card-text>
              <v-spacer />

              <v-card-actions
                class="justify-end"
                v-if="!hasFidelityCard && !hasRequestedFidelityCard"
              >
                <v-btn
                  color="primary"
                  depressed
                  :to="{
                    name: 'ProfileUpdate',
                    query: { navToRefName: 'wantPrima' }
                  }"
                >
                  {{ $t("primaCardRow.text.getPrimaCard") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- Buttons -->
      <v-row>
        <v-col
          cols="6"
          v-for="(item, idx) in dashboardItems"
          :key="idx"
          class="pa-3"
        >
          <DashboardCard
            :title="$t(`profile.menu.${item.title}`)"
            :icon="item.icon"
            :routePath="item.routePath"
            :navigation="item.navigation"
            :logoutFunc="item.logout"
          />
        </v-col>
      </v-row>
    </v-container>

    <div
      v-if="$platform_is_cordova && isBetaTester"
      class="my-10 development-link front-weight-bold"
    >
      <a :href="developmentLink">Apri versione di test</a>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import get from "lodash/get";
import DashboardCard from "@/components/profile/DashboardCard.vue";
// import FidelityCardDialog from "@/components/profile/FidelityCardDialog.vue";
import { mapGetters, mapActions, mapState } from "vuex";

import RegistrationService from "~/service/userService";

export default {
  name: "Profile",
  data() {
    return {
      flipped: false,
      oldBrightness: null,
      dashboardItems: [
        {
          title: "wall",
          icon: "$settings",
          routePath: "/profile/dashboard",
          navigation: true
        },
        {
          title: "profile",
          icon: "$profile",
          routePath: "/profile/profile",
          navigation: true
        },
        {
          title: "rider",
          icon: "$rapid",
          routePath: "/profile/rider",
          navigation: true
        },
        {
          title: "lists",
          icon: "$heart",
          routePath: "/profile/lists",
          navigation: true
        },
        {
          title: "addresses",
          icon: "$location",
          routePath: "/profile/addresses",
          navigation: true
        },
        {
          title: "coupon",
          icon: "$tag",
          routePath: "/profile/gifts",
          navigation: true
        },
        {
          title: "receipt",
          icon: "$receipt",
          routePath: "/profile/paymentduedate",
          navigation: true
        },
        {
          title: "messages",
          icon: "$mail",
          routePath: "/profile/news-list",
          navigation: true
        },
        // {
        //   title: "notification",
        //   icon: "$info",
        //   routePath: "/profile/notification",
        //   navigation: true
        // },
        {
          title: "inviteFriend",
          icon: "$people",
          routePath: "/profile/invite",
          navigation: true
        },
        {
          title: "euGenio",
          icon: "$eugenio",
          routePath: "/page/eugenio",
          navigation: true
        },
        // {
        //   title: "awards",
        //   icon: "$star",
        //   routePath: "/profile/awards",
        //   navigation: true
        // },
        // {
        //   title: "wallet",
        //   icon: "$euro",
        //   routePath: "/profile/points",
        //   navigation: true
        // },
        {
          title: "freeWifi",
          icon: "$wifi",
          routePath: "checkPdvNetwork",
          navigation: false
        },
        {
          title: "logout",
          icon: "$logout",
          routePath: "doLogout",
          navigation: false,
          logoutFunc: true
        }
      ],
      user: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      isBetaTester: "cart/isBetaTester"
    }),
    fidelityCard() {
      return get(this.cart, "user.fidelityCard");
    },
    hasFidelityCard() {
      if (this.cart && this.cart.user && this.cart.user.fidelityCard) {
        return true;
      } else {
        return false;
      }
    },
    hasRequestedFidelityCard() {
      return (
        this.cart &&
        this.cart.user &&
        this.cart.user.person &&
        this.cart.user.person.personInfos.some(info => {
          return info.personInfoTypeId === "4" && info.value === "1";
        })
      );
    }
  },
  async created() {
    await this.getCart();
    this.fetchUserData();
    screen.orientation.addEventListener("change", function() {
      if (this.flipped) {
        console.log(screen.orientation.type); // e.g. portrait
      }
    });
  },
  components: {
    DashboardCard
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart",
      logout: "cart/doLogout"
    }),
    fetchUserData() {
      RegistrationService.getUserDetail().then(user => {
        this.user = user;
        if (this.user.userType.userTypeId != 3) {
          this.dashboardItems = this.dashboardItems.filter(item => {
            return item.title != "rider";
          });
        }
      });
    },
    toggleCard() {
      var _this = this;
      this.flipped = !this.flipped;
      if (this.flipped) {
        // eslint-disable-next-line no-undef
        cordova.plugins.brightness.getBrightness(
          function(data) {
            _this.oldBrightness = data;
            // eslint-disable-next-line no-undef
            cordova.plugins.brightness.setBrightness(1);
          },
          function(err) {
            console.log("error: " + err);
          }
        );
      } else {
        // eslint-disable-next-line no-undef
        cordova.plugins.brightness.setBrightness(_this.oldBrightness);
      }
    },
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    },
    developmentLink() {
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile/develop/" + platform + "/index.html";
    }
    // async openFidelityModal() {
    // this.dialogInstance = await this.$dialog.show(FidelityCardDialog, {
    //   waitForResult: true,
    //   fidelityCard: this.fidelityCard,
    //   fullscreen: this.$vuetify.breakpoint.xsOnly
    // });
    // this.toggleModalBackdropClass();
    // }
    // toggleModalBackdropClass() {
    //   var modal = document.getElementsByClassName("modal-fidelity")[0];
    //   var backdropElement = modal.parentElement.previousElementSibling;
    //   backdropElement.classList.toggle("modal-fidelity-backdrop");
    // }
  }
};
</script>
